<template lang="pug">
// 新增客戶
v-card
  v-card-title.d-flex.justify-center {{$t('ADD_SEGMENT.TITLE')}}
  v-card-text
    v-form(v-model="valid", ref="form")
      v-text-field.mb-4(
        v-model='addData.name',
        :label=`$t('ADD_SEGMENT.NAME')` name='name',
        :rules='formRule.nameRules', 
        required='required', 
        large='large'
      )
      v-select.no-border(
        :label=`$t('ADD_SEGMENT.TYPE')`,
        v-model="addData.type",
        prepend-icon="mdi-chart-pie",
        :items="typeList",   
        item-text="name",
        item-value="key",     
        flat,
        readonly
      )     
      v-textarea.no-border.textarea-style(
        v-model="addData.description",
        prepend-icon="mdi-text",
        :label=`$t('ADD_SEGMENT.DESCRIPTION')`,
		no-resize
      ) 
  v-card-actions.px-2.py-4
    v-row
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="primary",
          depressed,
          dark
        ) {{$t('GENERAL.ADD')}}
  v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
</template>

<script>
import Vue from "vue";
import SegmentDataService from "@/services/SegmentDataService";
import industryList from "@/array/the_IndustryList";
import segment_typeList from "@/array/the_segmentTypeList";
import messageDialog from '@/components/Dialog/redirectDialog';
import i18n from '/common/plugins/vue-i18n.js' 


export default Vue.extend({
  props: {
    addSegment: {
      type: Object,
      required: true,
    },
    fromContact: {
      type: Boolean,
      required: false,
    },
  },
  components: {            
            messageDialog
        },
  data() {
    return {
      addData : {},
      messageDialog:false,
      message:"",
      segId:null,
      valid: true,
      search: null,
      isLoading: false,
      organizationList: [],
      organizationImage: null,
      industryList: industryList,
      isWatch: true,
      typeList: segment_typeList,
      formRule: {
        nameRules: [
            (v) => !!v || i18n.t('RULE.RULE_R_SEGMENT'),
            (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
        ],
      },
    };
  },
  created(){
    this.addData.name = this.addSegment.name;
    this.addData.type = this.addSegment.type;
    this.addData.description = this.addSegment.description;
    this.addData.id = this.addSegment.id? this.addSegment.id:'0';
    this.addData.contacts = this.addSegment.contacts ? this.addSegment.contacts : 0;
  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) 
        return true;
      else 
        return false;
    },
    async onConfirm() {
      if (this.validateForm()) {
        if (this.addData.contacts == 0)
          delete this.addData.contacts;
        await SegmentDataService.addSegment(this.addData)
          .then(response => {            
            this.$emit("emitAddSegData",response.data);
            this.segId = response.data.id
        });   
        if(!this.fromContact)
        {
          this.$emit("emitAddSeg", true);}

        else{
          this.showMessageDialog()
        }
        
      }
    },
    onCancel() {     
      this.$emit("emitAddSeg", false);
    },
    showMessageDialog() {                
                this.messageDialog = true;
                this.message = i18n.t('ADD_SEGMENT.MESSAGE');
            },
    async onEmitMessageDialog(val) {
        if(val)
        {                    
            this.$router.push('/Segment/'+this.segId)
            this.messageDialog = false; 
            this.$router.go();
        }  
        else{
            this.messageDialog = false;
            this.$emit('emitAddSeg', true);
        }             
    },
  },
});
</script>