<template lang="pug">
v-card(:class='contactList.length >= 8 ? "dialog-height-big" : ""')
  v-col.d-flex.align-center.pa-5.pb-0
    v-row(no-gutters)
      v-col(cols="7")
        .d-flex.align-center
          h2.my-0 {{$t('ADDSEGMENTCONTACT.TITLE')}}         
      v-col.text-right(cols="4")
        .btn-group.ml-auto.mr-2
          v-text-field(
            v-model="searchMainContact",
            flat,
            dense,
            solo,
            prepend-inner-icon="icon-search",
            hide-details
          )
      v-col.text-right(cols="1")
        v-btn.ml-auto(
          :ripple="false",
          @click="onCancel",
          depressed="depressed",
          icon
        )
          v-icon icon-cancel
  v-card-text.pt-4.px-5.pb-5
    .data-area
      v-data-table.border-table(
        :headers="mainContactHeader",
        :items="contactData",
        :items-per-page='itemPerPage' 
        :page.sync='page' 
        :page-count='pageCount'   
        :sort-desc.sync='sortByDesc'
        :sort-by.sync="sortBy"
        :header-props="{sortIcon: 'mdi mdi-arrow-up'}"
        multi-sort
        @page-count="mainContactPageCount = $event",
        hide-default-footer="hide-default-footer"
        show-select item-key='id' v-model="selectedRows" :loading='isLoading' loading-text="Loading..." 
      )        
        template(v-slot:item.full_name="{ item }")
            .ellipsis
                a(:href="'/Contacts/' + item.id" :title='item.full_name') {{ item.full_name }}        
        template(v-slot:item.org_name="{ item }")
            v-btn.w-100.d-block(v-if="item.org_name != null" :href="onCall(item.org_name)" color="primary" text :title='item.org_name') 
                .ellipsis.pt-2.w-100 
                  span.w-100 {{ item.org_name }}
        template(v-slot:item.mobile_phone="{ item }")
            v-btn.w-100.d-block(v-if="item.mobile_phone != null" :href="onCall(item.mobile_phone)" color="primary" text :title='item.mobile_phone') 
                .ellipsis.pt-2.w-100 
                    span.w-100 {{ item.mobile_phone }}
        template(v-slot:item.email="{ item }")
            v-btn.w-100.d-block(v-if="item.email != null" :href="onMail(item.email)" color="primary" text :title='item.email') 
                .ellipsis.pt-2.w-100 
                  span.w-100 {{ item.email }}
        template(v-slot:item.active="{ item }")
          v-btn(icon="icon", @click="showEditContact(item)")
            v-icon(size="18") icon-edit
          v-btn(
            icon="icon",
            @click="onDelete(item.organizationcontacts_set[0].id)"
          )
            v-icon(size="18") icon-remove
      v-row(no-gutters).my-5
        v-col.d-flex.justify-end(cols="12")
            v-btn(:ripple='false' @click='onConfirm' width='150' height='40' color='success' depressed dark) {{$t('ADDSEGMENTCONTACT.ADD_CONTACT')}}  
      v-pagination.mt-3(
        v-model='page' :length='pageCount' circle :total-visible="10" v-if='!isLoading'
      )  
  v-dialog(
    v-model="successDialog",
    width="360",
    content-class="statusDialog"
  )
    success-dialog(@emitSuccessDialog="onEmitSuccessDialog")
  v-dialog(
    v-model="messageDialog",
    width="360",
    content-class="statusDialog"
  )
    message-dialog(
      @emitMessageDialog="onEmitMessageDialog",
      :message="message"
    )
</template>

<script>
import Vue from "vue";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import SegmentDataService from "@/services/SegmentDataService";
import ContactDataService from "@/services/ContactDataService";
import i18n from '/common/plugins/vue-i18n.js' 

export default Vue.extend({
  props: {
    segContactList: {
      type: Array,
      required: true,
    },
  },
  components: { 
    successDialog,
    messageDialog,
  },
  async created() {
      this.getListData()
    // await SegmentDataService.getContactList(this.$route.params.id)
    // .then(response => {
    //   this.contactList = (response.data).reverse();      
    //   this.contactList.forEach((data) => {
    //     data.full_name = data.last_name;
    //     if(data.first_name != null){
    //       data.full_name = data.last_name +" "+ data.first_name;
    //     }
    //     if (data.organization_set.length != 0) {
    //       data.org_name = data.organization_set[0].name;
    //     }
    //     // if (data.owner.first_name == null) {
    //     //   data.owner_name = data.owner.last_name;
    //     // } 
    //     // else {
    //     //   data.owner_name = data.owner.last_name +" "+ data.owner.first_name;
    //     // }
    //     if(this.segContactList.includes(data.id))
    //     {           
    //       this.contactList = this.contactList.filter(e=>e.id != data.id)
    //     }
    //   });
    //   this.isLoading = false;
    // });
    // window.addEventListener('resize', this.onResize)
    // this.onResize();    
  },
  data() {
    return {
      isLoading:true,
      selectedRows:[],
      contactList:[],
      searchMainContact: "",
      // mainContactPage: 1,
      // mainContactPageCount: 0,
      // mainContactItemPerPage: 8,
      awaitingSearch: false,   
      sortBy:[],
      sortByForSearch:[],
      sortByDesc:[],
      pageDataCount:null,
      page: 1,
      pageCount: 0,
      itemPerPage:8,
      mainContactHeader: [
        {
          value: "data-table-select",
          align: "center",
          width: '5%',
        },        
        {
          text: i18n.t('ADDSEGMENTCONTACT.FIRST_NAME'),
          value: "full_name",
          align: "left",
          filterable: true,
          width: '12%',
        },      
        {
          text: i18n.t('ADDSEGMENTCONTACT.ORG_NAME'),
          value: "org_name",
          align: "left",
          width: '15%',
          filterable: true,
        },
        {
          text: i18n.t('ADDSEGMENTCONTACT.PHONE'),
          value: "mobile_phone",
          align: "left",
          filterable: true,
          width: '10%',
        },
        { 
          text: i18n.t('ADDSEGMENTCONTACT.MAIL'), 
          value: "email", 
          align: "left", 
          filterable: true,
          width: '18%', 
        },
      ],      
      successDialog: false,
      messageDialog: false,
      message: "",
      addContact:[],
      // Dialog
      addMainContactDialog: false,
      editMainContactDialog: false,
      addMainContactData:{},
      edtorMainContactData:{},
    };
  },
  methods: {
    async getListData(){
        this.isLoading = true;
        this.contactData = [];
        await ContactDataService.getAll(this.page,this.searchMainContact,8,this.sortByForSearch)
        .then(response => {      
            this.contactData = response.data.list;             
            this.pageCount = response.data.total_page
            this.pageDataCount = response.data.pageDataCount
            this.contactData.forEach(data => {
                data.total_amount_adj = '$ '+this.getFormatAmount(data.total_amount);
                data.full_name = data.last_name;
                if(data.first_name != null){
                    data.full_name = data.last_name +" "+ data.first_name;
                }
                if (data.organization_set.length != 0) {
                    data.org_name = data.organization_set[0].name;
                }
                if (data.owner.first_name == null) {
                    data.owner_name = data.owner.last_name;
                } 
                else {
                    data.owner_name = data.owner.last_name +" "+ data.owner.first_name;
                }

                if(this.segContactList.includes(data.id))
                {       
                  this.selectedRows.push(data)                      
                }
            });
            this.isLoading = false;        
            // this.sortByForSearch = []
        });
        window.addEventListener('resize', this.onResize)
        this.onResize();
    },
    onResize: function () {
      let windowH = window.innerHeight - 150; //table高度
      let objH = 70;
      this.itemPerPage = parseInt(windowH/objH);
    },
    validate() {
      this.$refs.form.validate();
    },    
    // Common Function
    onCall: function (cell) {
      return "tel:" + cell;
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
      this.contactList.forEach((data) => {        
        if(this.segContactList.includes(data.id))
        {           
          this.contactList = this.contactList.filter(e=>e.id != data.id)
        }
        this.selectedRows = []
      });
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    }, 
    onEmitMessageDialog() {
      this.messageDialog = false;      
    },   
    changePostData(){       
      if (this.selectedRows.length > 0){        
        for(let i = 0; i < this.selectedRows.length; i++)
        {          
          this.segContactList.splice(0,0,this.selectedRows[i].id)
        }
      }    
    },
    async onConfirm() {        
      await this.changePostData();
      let target_id = this.$route.params.id !=undefined ? this.$route.params.id : sessionStorage.getItem('page_id');
      this.newContactList = {id:target_id,contacts:this.segContactList}
             
      await SegmentDataService.editDetail(target_id,this.newContactList)
      .then((response)=>{
        this.$emit("emitAddSegData",response.data.contact_set);
        this.showSuccessDialog()
        })
    },
    onCancel() {      
      this.$emit("emitAddSegContactDialog", false);
      this.$emit("emitMainContactsDialog", false);
    },
  },
  watch:{    
    "page":async function () {      
      await this.getListData()  
    },
    "searchMainContact":async function () {
       if (!this.awaitingSearch) {
          setTimeout(() => {
            this.page = 1
            this.getListData();
            this.awaitingSearch = false;
          }, 1000);
        }
        this.awaitingSearch = true;
    },   
    "sortByDesc":async function () {   
      this.sortByForSearch = JSON.parse(JSON.stringify(this.sortBy))
      for(let i in this.sortByDesc)
      {
        if(this.sortByDesc[i] == true)
        {
          this.sortByForSearch[i] = '-'+this.sortByForSearch[i]          
        }        
      }
      this.page = 1;
      await this.getListData();
    },
  }
});
</script>