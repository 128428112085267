<template lang="pug">
v-card
   v-card-title.d-flex.justify-center.mb-4 {{$t('PRODUCTSETTING.EDIT_PRODUCT')}} 
                v-card-text
                    v-form(v-model='editProduct.valid' ref='form')
                        v-row(no-gutters)    
                            v-col(cols='12')
                                v-text-field.v-text-field__slot.height-58(v-model='editProduct.name' :label=`$t('PRODUCTSETTING.NAME')`,:rules='formRules.nameRules',  prepend-icon="fa-file-signature")
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-combobox.v-text-field__slot.height-58( v-model='editProduct.category' :items="categoryList" item-value="id" item-text="name", :label=`$t('PRODUCTSETTING.CATEGORY')`,  prepend-icon='fa-tag' ,:ref="'check_category'")
                                    template(v-slot:append-outer)
                                        div(v-if="isNew")
                                            v-icon(color="red", size="25") mdi-new-box
                                    template(v-slot:item="data")
                                        div.ellipsis(v-if="data.item.id == 0")
                                            span {{ data.item.name }}
                                            v-icon(color="red", size="25") mdi-new-box
                                        div.ellipsis(v-else)
                                            span {{ data.item.name }}
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-combobox.v-text-field__slot.height-58( v-model='editProduct.series' :items="seriesList" item-value="id" item-text="name", :label=`$t('PRODUCTSETTING.SERIES')`,  prepend-icon='fa-tag' ,:ref="'check_series'")
                                    template(v-slot:append-outer)
                                        div(v-if="isNew_2")
                                            v-icon(color="red", size="25") mdi-new-box
                                    template(v-slot:item="data")
                                        div.ellipsis(v-if="data.item.id == 0")
                                            span {{ data.item.name }}
                                            v-icon(color="red", size="25") mdi-new-box
                                        div.ellipsis(v-else)
                                            span {{ data.item.name }}
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-text-field.v-text-field__slot.height-58( v-model='editProduct.product_code' :label=`$t('PRODUCTSETTING.PRODUCTCODE')`,  prepend-icon="fa-solid fa-barcode")
                        v-row(no-gutters)
                            v-col(cols='12')
                                //- v-text-field.v-text-field__slot.height-58( v-model='editProduct.unit_price' :label=`$t('PRODUCTSETTING.PRICR')`,  prepend-icon="icon-money" :rules='formRules.amountRule')
                                v-text-field.v-text-field__slot.height-58( v-model='editProduct.unit_price' :label=`$t('PRODUCTSETTING.PRICR')`, no-resize prepend-icon="icon-money" :rules='formRules.amountRule',v-show='priceShow|!priceValidate' :ref="'editPrice'" @blur='showFormatPrice')
                                v-text-field.v-text-field__slot.height-58(v-model='formatPrice' prepend-icon='icon-money', :label=`$t('PRODUCTSETTING.PRICR')`,  @click='showEditPrice' @blur='showEditPrice' :readonly='true' v-show='!priceShow && priceValidate')
                        v-row(no-gutters)
                            v-col(cols='12')
                                //- v-text-field.v-text-field__slot.height-58( v-model='editProduct.unit_cost' :label=`$t('PRODUCTSETTING.COST')`,  prepend-icon="icon-money" :rules='formRules.amountRule')
                                v-text-field.v-text-field__slot.height-58( v-model='editProduct.unit_cost' :label=`$t('PRODUCTSETTING.COST')`, no-resize prepend-icon="icon-money" :rules='formRules.amountRule', ,v-show='costShow|!costValidate' :ref="'editCost'" @blur='showFormatCost')
                                v-text-field.v-text-field__slot.height-58(v-model='formatCost' prepend-icon='icon-money', :label=`$t('PRODUCTSETTING.COST')`,  @click='showEditCost' @blur='showEditCost' :readonly='true' v-show='!costShow && costValidate')
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-text-field.v-text-field__slot.height-58(v-model.number='editProduct.tax' type='number', :label=`$t('PRODUCTLINEITEM.TAX')`, no-resize prepend-icon="mdi-24px mdi-sale" :rules='formRules.amountRule')
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-text-field.v-text-field__slot.height-58( v-model.number='editProduct.other_charges' type='number', :label=`$t('PRODUCTLINEITEM.OTHER_CHARGES')`, no-resize prepend-icon="mdi-24px mdi-sale" :rules='formRules.amountRule')
                        
                        v-row(no-gutters v-if="editProduct.enable_trq")
                            v-col(cols='12')
                                v-menu( :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                    template(v-slot:activator="{ on, attrs }")
                                        v-text-field.v-text-field__slot.height-58(:label=`$t('PRODUCTLINEITEM.FROM')`, no-resize prepend-icon="icon-calendar" v-model='editProduct.from_date'  v-bind="attrs" v-on="on"  hide-details="auto"  :rules='editProduct.to_date ? formRules.fromToRule:[]' class='num_field' readonly clearable :change='toDateCheck()' )
                                    v-date-picker(v-model='editProduct.from_date' @input='menu_from = false' no-title scrollable) 
                        v-row(no-gutters v-if="editProduct.enable_trq").pt-3
                            v-col(cols='12')
                                v-menu( :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                    template(v-slot:activator="{ on, attrs }")
                                        v-text-field.v-text-field__slot.height-58(:label=`$t('PRODUCTLINEITEM.TO')`, no-resize prepend-icon="icon-calendar" v-model='editProduct.to_date'  v-bind="attrs" v-on="on" hide-details="auto"  :rules='editProduct.from_date ? formRules.fromToRule:[]' class='num_field' readonly clearable  )
                                    v-date-picker(v-model='editProduct.to_date' @input='menu_to = false' no-title scrollable :min='minDate(editProduct.from_date)') 
                    
                        v-row(no-gutters)
                            v-col(cols='12')
                                v-textarea.textarea-style.no-border.v-text-field__slot(v-model='editProduct.description' :label=`$t('PRODUCTSETTING.DESCRIPTIONL')`, no-resize ,prepend-icon="mdi-equal-box")
                    v-row(no-gutters)
                        v-col(cols='12')
                            v-checkbox.v-text-field__slot.height-58( v-model='editProduct.enable_trq' :label=`$t('PRODUCTLINEITEM.ENABLE_TRQ')`, no-resize)
                    v-card-actions.mt-4.px-0.py-0
                        v-row(no-gutters)
                            v-col.pr-2(cols='6')
                                v-btn(:ripple='false' @click='onCancel()' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                            v-col.pl-2(cols='6')
                                v-btn(:ripple='false' @click='onProductEdit(editProduct)' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.SAVE')}}
</template>

<script>
import Vue from "vue";
import ProductDataService from "@/services/ProductDataService";
import segment_typeList from "@/array/the_segmentTypeList";
import i18n from '/common/plugins/vue-i18n.js' 


export default Vue.extend({
  props: {
    editProduct: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      oringalData:null,
      priceValidate:false,
      costValidate:false,
      formatPrice:null,
      priceShow:false,
      formatCost:null,
      costShow:false,
      valid: true,
      search: null,
      isLoading: false,
      categoryList:[],
      seriesList:[],
      isWatch: true,
      typeList: segment_typeList,
      formRules: {
      nameRules: [                        
                  (v) => !!v || i18n.t('RULE.RULE_R'),
                  (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
              ],
      amountRule: [(v) => /^\d{1,17}(\.\d{0,2})?$|(^$)|null/.test(v) || i18n.t('RULE.RULE_R_NUM_2')],
      fromToRule: [
                (v) => !!v || i18n.t('PRODUCTLINEITEM.FROMTOERROR'),
                (v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v) && !!v || i18n.t('RULE.RULE_DATE')
            ],
      },
      newCate:false,
        newSeries:false,
    };
  },
  async created()
        {
            this.getcategorylist()
            this.getserieslist()
            this.oringalData = JSON.parse(JSON.stringify(this.editProduct)),
            this.showFormatPrice("edit")
            this.showFormatCost("edit")
        },
  methods: {
            minDate(date){
                let current_date = new Date(date).getTime();
                let min = current_date + 1000*60*60*24;
                return (new Date(min).getFullYear()).toString() +'-' +(new Date(min).getMonth()+1).toString().padStart(2,0)+'-'+ (new Date(min).getDate()).toString().padStart(2,0)
            },
            toDateCheck(){
                if(this.editProduct.from_date){
                    let FD = new Date(this.editProduct.from_date).getTime();
                    let TD = new Date(this.editProduct.to_date).getTime();
                    if(this.editProduct.to_date && (TD-FD)<=0){
                        this.editProduct.to_date = this.minDate(this.editProduct.from_date);
                    }
                }
            },
            async getcategorylist(){
                await ProductDataService.productcategorylist()
                .then(response => 
                {  
                    this.categoryList = response.data;
                });
            },
            async getserieslist(){
                await ProductDataService.productserieslist()
                .then(response => 
                {  
                    this.seriesList = response.data;
                });
            },
            showFormatPrice(val){
                this.priceShow = false;
                if(this.editProduct)
                {
                  this.formatPrice = this.getFormatAmount(this.editProduct.unit_price);
                }
                if(val == "edit")
                {
                    this.priceValidate = true;
                }else{
                    this.priceValidate = this.$refs.editCost.validate();
                }                
            },
            showEditPrice(){
                this.priceShow = true
                this.$refs.editPrice.focus()
            },
            showFormatCost(val){
                this.costShow = false;
                if(this.editProduct)
                {
                    this.formatCost = this.getFormatAmount(this.editProduct.unit_cost);
                }
                if(val == "edit")
                {
                    this.costValidate = true;
                }else{
                    this.costValidate = this.$refs.editCost.validate();
                }
            },
            showEditCost(){
                this.costShow = true
                this.$refs.editCost.focus()
            },
    validate() {
      this.$refs.form.validate();
    },
    validateForm() {
      if (this.$refs.form.validate()) 
        return true;
      else 
        return false;    
    },
    onCancel() {      
      // this.oringalData = JSON.parse(JSON.stringify(this.editProduct))
      this.formatPrice = null;
      this.formatCost = null;
      this.$emit("emitEditDialog", this.oringalData);
    },
     async onProductEdit(data) {
            await(this.$refs.check_category.blur())
            await(this.$refs.check_series.blur())
          if(this.validateForm()){
              if (this.editProduct.series != null) {
                if (typeof(this.editProduct.series) == "string") {   
                    this.editProduct.series = this.editProduct.series.trim();
                    const name = this.editProduct.series;
                    if (this.editProduct.series == "") {
                        this.editProduct.series = null;
                    } else {
                        this.editProduct.series = {"id": "0", "name": name};
                        this.newSeries = true;
                    }
                } else {
                    this.editProduct.series = data.series.name != "" ? data.series : null;
                }
            }

            if (this.editProduct.category != null) {
                if (typeof(this.editProduct.category) == "string") {
                    this.editProduct.category = this.editProduct.category.trim();
                    const name = this.editProduct.category;
                    if (this.editProduct.category == "") {
                        this.editProduct.category = null;
                    } else {
                        this.editProduct.category = {"id": "0", "name": name};
                        this.newCate = true;
                    }
                } else {
                    this.editProduct.category = data.category.name != "" ? data.category : null;
                }
            }
              
              this.editProduct.tax = this.editProduct.tax ? this.editProduct.tax :0;
              this.editProduct.other_charges = this.editProduct.other_charges ? this.editProduct.other_charges :0;
              await ProductDataService.edit(this.editProduct).then((response)=>{
                  this.$emit("emitEditDialog",response.data);
              })
                if(this.newSeries)
                {
                    this.getserieslist();
                    this.newSeries = false;
                }
                if(this.newCate)
                {
                    this.getcategorylist();
                    this.newCate = false;
                }
        }}
  },
  computed: {
            isNew: function(){
            let datamode = this.editProduct.category
            if(datamode == "" || datamode == null){
                return false;
            }
            if(typeof (datamode) === 'object'){
                if(datamode.id == 0){
                return true;
                }
                else{
                return false;
                }
            }
            else if(typeof (datamode) === 'string'){
                datamode = datamode.trim();
                if(datamode != ''){
                    return true;
                }else{
                    return false;
                }
            }
            else{
                return true;
            }
            },
            isNew_2: function(){
            let datamode = this.editProduct.series
            if(datamode == "" || datamode == null){
                return false;
            }
            if(typeof (datamode) === 'object'){
                if(datamode.id == 0){
                return true;
                }
                else{
                return false;
                }
            }
            else if(typeof (datamode) === 'string'){
                datamode = datamode.trim();
                if(datamode != ''){
                    return true;
                }else{
                    return false;
                }
            }
            else{
                return true;
            }
            },
        },
  watch:{
    "editProduct.id":function(){
      this.oringalData = JSON.parse(JSON.stringify(this.editProduct)),
      this.showFormatPrice("edit")
      this.showFormatCost("edit")
    }
  }
});
</script>