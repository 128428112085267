<template lang="pug">
v-card
  v-card-title.d-flex.justify-center {{$t('EDIT_SEGMENT.TITLE')}}
  v-card-text
    v-form(v-model="valid", ref="form")
      v-text-field.mb-4(
        v-model='segData.name',
        :label=`$t('EDIT_SEGMENT.NAME')` name='name',
        :rules='formRule.nameRules', 
        required='required', 
        large='large'
      )      
      v-select.no-border(
        :label=`$t('EDIT_SEGMENT.TYPE')`,
        v-model="segData.type",
        prepend-icon="mdi-chart-pie",
        :items="typeList",   
        item-text="name",
        item-value="key",     
        flat,
        readonly
      )     
      v-textarea.no-border.textarea-style(
        v-model="segData.description",
        prepend-icon="mdi-text",
        :label=`$t('EDIT_SEGMENT.DESCRIPTION')`,
		no-resize
      )      
  v-card-actions.px-2.py-4
    v-row
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="primary",
          depressed,
          dark
        ) {{$t('GENERAL.SAVE')}}
</template>

<script>
import Vue from "vue";
import SegmentDataService from "@/services/SegmentDataService";
import industryList from "@/array/the_IndustryList";
import segment_typeList from "@/array/the_segmentTypeList";
import i18n from '/common/plugins/vue-i18n.js' 


export default Vue.extend({
  props: {
    segData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {      
      valid: true,
      search: null,
      isLoading: false,
      organizationList: [],
      organizationImage: null,
      industryList: industryList,
      isWatch: true,
      typeList: segment_typeList,
      formRule: {
        nameRules: [
            (v) => !!v || i18n.t('RULE.RULE_R_SEGMENT'),
            (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
        ],
      },
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    validateForm() {
      if (this.$refs.form.validate()) 
        return true;
      else 
        return false;
    },
    async onConfirm() {      
      if (this.validateForm()) {
        await SegmentDataService.editSegment(this.segData)                 
        this.$emit("emitEditSeg", true);        
		
      }
    },
    onCancel() {     
      this.$emit("emitEditSeg", false);
    },
  },
});
</script>