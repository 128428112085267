<template lang="pug">
v-card.noteDialog
    v-card-title.d-flex.justify-center {{$t('DOCUMENT.EDIT')}}
    v-card-text
        v-form(v-model='valid' ref='form')
            v-file-input.no-border(v-model="document_file",prepend-icon="mdi-file-document",:label=`$t('DOCUMENT.NEW_FILE')`,@change='checkFile',:rules='formRule.fileRule',)
            v-text-field.no-border(v-model="editDocument.name",prepend-icon="mdi-tag",:rules="formRule.nameRule",:label= `$t('DOCUMENT.NAME')`)
            v-textarea.no-border(:label=`$t('DOCUMENT.DESCRIPTION')`,prepend-icon="mdi-transcribe"  v-model='editDocument.description' validate-on-blur required="required" no-resize)
    v-card-actions.px-2.py-4
        v-row
            v-col.pr-2(cols='6')
                v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
            v-col.pl-2(cols='6')
                v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed :disabled='upLoading') 
                    v-progress-circular(v-if='upLoading' indeterminate size='20')
                    .t-white(v-else) {{$t('GENERAL.CONFIRM')}}
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
	import Vue from 'vue';
    import DocumentDataService from "@/services/DocumentDataService";
    import i18n from '/common/plugins/vue-i18n.js' 
    import messageDialog from '@/components/Dialog/redirectDialog';
    import errorDialog from "@/components/Dialog/errorDialog";

	export default Vue.extend({
    props: {
        docData:{
            type: Object,
            require: true,
        },
        class_name:{
            type: String,
            require: true,
        },
        target_id:{
            type: String,
            require: true,
        },
    },
    components: {
        errorDialog,
        messageDialog
    },
    created(){
        this.editDocument = this.docData
    },
        data() {
			return {
                upLoading:false,
                valid: true,
                messageDialog:false,
                message:"",
                errorMessage:'',
                errorDialog:false,
                fileSize:false,
                editDocument:{},
                document_file:null,
                formRule: {
                    contentRules: [
                        v => !!v || i18n.t('RULE.RULE_NOTE'),
                        v => (v && v.length <= 1000) || i18n.t('RULE.RULE_NOTE_1000'),
                    ],  
                    fileRule: [
                        () => { return (!this.fileSize || i18n.t('RULE.RULE_FILE_3'))},
                    ],
                    fieldRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R'),
                    ],
                    nameRule:[
                        (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50'),
                        (v) => !!v || i18n.t('RULE.RULE_R'),
                    ]
                },
			}
		},
        methods: {
			validate() {
				this.$refs.form.validate()
			},
            validateForm(){
                if(this.$refs.form.validate())
                    return true;
                else
                    return false;
            },
            getDataFromResponse(data){
                let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false  };
                let created  = new Date(data.created_on);
                let updated  = new Date(data.updated_on);
                let formatted_created = created.toLocaleDateString("zh-TW", options); 
                let formatted_updated = updated.toLocaleDateString("zh-TW", options); 
                if(this.$i18n.locale != "zh_TW")
                {
                    formatted_created = created.toLocaleDateString("en-US", options); 
                    formatted_updated = updated.toLocaleDateString("en-US", options); 
                }
                data.created_on = formatted_created;
                data.updated_on = formatted_updated;
                return data
            },
			async onConfirm() {
                if(this.validateForm()&& !this.fileSize ){
                    this.upLoading = true;
                    let newData={
                            name:this.editDocument.name,
                            description:this.editDocument.description,
                            class_name:this.class_name,
                            linked_id:this.target_id,
                            id:this.editDocument.id
                        };
                    await DocumentDataService.edit(newData,this.document_file)
                    .then(response => {
                        let data = response.data;
                        this.getDataFromResponse(data);
                        this.$emit('emitEditDialog',data);
                        this.editDocument = {};
                        this.document_file = null;
                        this.upLoading = false;
                    });
                }
			},
			onCancel() {
                this.document_file = null;
				this.$emit('emitEditDialog', true);
			},
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            checkFile(event) {
                if (event != null) {
                    // file size
                    if (event.size > 20971520) {
                        this.fileSize = true;
                    } else {
                        this.fileSize = false;
                    }
                } else {
                    this.fileSize = false;
                    this.fileType = false;
                }
            },
		},
        watch:{
            "document_file": function(){
                if(this.document_file != null && this.editDocument.name == null){
                    this.editDocument.name = this.document_file.name;
                }
            },
            "docData": function(){
                this.editDocument = this.docData;
            },
        }
	});
</script>

